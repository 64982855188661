import Dialog from "../../../common/Dialog/Dialog";
import {Box, Grid, Typography} from "@mui/material";
import {TypeBox} from "./TypeBox";
import React, {useCallback, useEffect, useState} from "react";
import Button from "../../../common/Button/Button";
import Input from "../../../common/Input/Input";
import {ScoopLoader} from "../../../common/Spinner/ScoopLoader";
import {Server} from "../../../../api/Server";
import {useDispatch, useSelector} from "react-redux";
import ApolloIcon from "../../../../assets/icons/apollo.svg";
import {APIConnector} from "../../Appconnect/API/APIConnector";
import {useNavigate} from "react-router-dom";
import SearchBar from "../../../common/SeacrhBar/SearchBar";
import {debounce, toLower} from "lodash";

export const NewFromRecipe = ({isOpen, setOpen}) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector(state => state.auth.token);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
    const [recipeMaps, setRecipeMaps] = useState(null);
    const [recipeMapsCopy, setRecipeMapsCopy] = useState(null)
    const [step, setStep] = useState(0);
    const [connectorType, setConnectorType] = useState(null);
    const [selectedMap, setSelectedMap] = React.useState(null);
    const [connectionKey, setConnectionKey] = React.useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [canvasList, setCanvasList] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const debounceFn = debounce((value) => {
        recipeMaps && setRecipeMaps(recipeMapsCopy.filter(recipeMap => recipeMap.recipeMapName.toLowerCase().includes(toLower(value))));
    }, 200);

    useEffect(() => {
        if (userID && token && workspaceID) setServer(new Server(workspaceID, userID, token));
    }, [userID, token, workspaceID]);

    useEffect(() => {
        if (server?.token) {
            setIsLoading(true)
            server.postData({
                "action": "getRecipeMappings"
            }, (results) => {
                if (results.recipes) {
                    setRecipeMaps(results.recipes);
                    setRecipeMapsCopy(results.recipes);
                    setIsLoading(false)
                }
            }, undefined, () => setIsLoading(false));
        }
    }, [])

    const handleSearchChange = (event) => {
        debounceFn(event.target.value);
    };

    const renderSelectMapping = () => {
        if (!canvasList) {
            getCanvasList();
        }
        return (
            <Dialog
                style={{width: '1200px', height: '720px'}}
                open={isOpen} maxWidth="lg"
                    onClose={() => {
                        setOpen(false);
                    }}
                    actions={
                        <>
                            <Button
                                className={'button-grey small'} onClick={() => {
                                setOpen(false);
                            }}>
                                Cancel
                            </Button>
                        </>
                    }
            >
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0px'}}>
                    <Typography sx={{color: '#201024', fontSize: '24px', fontWeight: 600}}>Create New Canvas from
                        Recipe</Typography>
                    <Typography sx={{display: 'inline', textAlign: 'center', width: '75%', color: '#635566'}}>
                        Select from a list of pre-defined recipes
                    </Typography>
                </Box>
                <Box sx={{width: '450px', marginLeft: 'auto', marginRight: '8px'}}>
                    <SearchBar placeholder={'Search Recipes'} onChange={handleSearchChange} />
                </Box>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                        <ScoopLoader size={72} sx={{ margin: 'auto' }} />
                    </Box>
                ) : (
                    <Grid sx={{ maxHeight: '450px', overflow: 'auto', height: '420px' }} container>
                        {(recipeMaps && recipeMaps.length > 0) ? recipeMaps.map((recipeMap) => {
                            return (
                                <Grid item key={recipeMap.recipeMapName} xs={6} padding={1}>
                                    <Box className="source-box" onClick={() => { setSelectedMap(recipeMap); setStep(1); }}
                                         sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                            <img src={recipeMap.logoURL} style={{ width: '24px', height: '24px' }} />
                                            <Typography sx={{ fontWeight: 'bold', width: '100%', ml: 1 }}>
                                                {recipeMap.recipeMapName}
                                            </Typography>
                                            <Typography sx={{ color: 'grey', fontSize: 12, whiteSpace: 'nowrap' }}>
                                                {recipeMap.recipeCategory}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14 }}>{recipeMap.descriptionHTML}</Typography>
                                            <img src={recipeMap.screenshotURL} style={{ width: '270px', height: '150px' }} />
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        }) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                                <Typography sx={{ color: '#635566', fontSize: '18px' }}>No recipes found</Typography>
                            </Box>
                        )}
                    </Grid>
                )}

            </Dialog>
        )
    }

    const onClose = () => {
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setStep(0);
    }

    const getCanvasList = (next) => {
        server.postData({
            "action": "getObjects",
            "class": "scoop.canvas.Canvas",
            "attributeName": "workspaceID",
            "attributeValue": workspaceID,
            "noCache": true
        }, (results) => {
            if (next) {
                next(results.objects);
            } else {
                setCanvasList(results.objects);
            }
        });
    }

    const testDone = (interval) => {
        if (server?.token) {
            server.postData({
                "action": "getProcessStatus",
                "workspaceID": workspaceID,
            }, (results) => {
                if (results.inboxStatus) {
                    let foundInstantiation = false;
                    for (let i = 0; i < results.inboxStatus.length; i++) {
                        if (results.inboxStatus[i].type === "instantiateRecipe" || results.inboxStatus[i].type === "extract") {
                            foundInstantiation = true;
                            break;
                        }
                    }
                    if (!foundInstantiation) {
                        getCanvasList((newCanvasList) => {
                            if (newCanvasList.length > canvasList.length) {
                                setStep(0);
                                clearInterval(interval);
                                onClose();
                                for (let i = 0; i < newCanvasList.length; i++) {
                                    let found = false;
                                    for (let j = 0; j < canvasList.length; j++) {
                                        if (newCanvasList[i].canvasID === canvasList[j].canvasID) {
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        dispatch({
                                            type: 'APPLY_LOADED_OBJECTS_STATE',
                                            payload: [],
                                        });
                                        navigate(newCanvasList[i].canvasID)
                                    }
                                }
                            }
                        })
                    }
                }
            });
        }
    }

    const handleConfirm = () => {
        if (server?.token) {
            server.postData({
                "action": "instantiateRecipe",
                "recipeMap": selectedMap,
                "connectionKey": connectionKey
            }, (results) => {
                if (results.status && results.status === "instantiatingRecipe") {
                    setStep(3);
                    const interval = setInterval(() => {
                        if (isOpen) {
                            testDone(interval);
                        } else {
                            clearInterval(interval);
                        }
                    }, 3000);
                }
            })
        }
    }

    switch (step) {
        case 0:
            return renderSelectMapping();
        case 1:
            return <APIConnector
                open={step === 1}
                onClose={onClose}
                stepBack={() => {
                    setStep(2);
                }}
                connectorType={selectedMap.connectorType}
                handleConnectOnly={true}
                setKey={setConnectionKey}
            />
        case 2:
            return <Dialog
                open={step === 2}
                onClose={handleClose}
                actions={[
                    <Button key={1} className={'small'} onClick={handleClose}>Cancel</Button>,
                    <Button key={2} className={'button-purple small'} onClick={handleConfirm}>Confirm</Button>
                ]}
                title={'Are you sure?'}
            >
                <Typography className={'inter'}>Confirm that you would like to create a new Canvas based on the
                    following recipe and data source</Typography>
                <Grid container={true} spacing={0} sx={{width: '100%', font: 'Integer', fontWeight: '400'}}>
                    <Grid item xs={4} sx={{fontWeight: '600'}}>Recipe:</Grid>
                    <Grid item xs={8}>{selectedMap.recipeMapName}</Grid>
                    <Grid item xs={4} sx={{fontWeight: '600'}}>Data Source:</Grid>
                    <Grid item xs={8}>{selectedMap.connectorType}</Grid>
                </Grid>
            </Dialog>
        case 3:
            return <Dialog
                open={step === 3}
                actions={[]}
                title={'Setting Up Recipe'}>
                <Box sx={{width: '500px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '16px'}}>
                    <Typography className={'inter'} sx={{mt: 3, mb: 2}}>Setting up your Scoop recipe</Typography>
                    <ScoopLoader size={72} sx={{mb: 2}}></ScoopLoader>
                </Box>
            </Dialog>
    }
    return null;
}