import React, {useEffect, useState} from "react";
import Button from "../../../common/Button/Button";
import {
    Box, Typography,
} from '@mui/material';
import {Server} from "../../../../api/Server";
import {useSelector} from "react-redux";

export const APIConnectorAuth = () => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector(state => state.auth.token);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
    const [connector, setConnector] = React.useState(null);

    // this flow means the user came in via the setup page and now has to authorize gmail
    const [isFinishSetupFlow, setIsFinishSetupFlow] = useState(false);

    useEffect(() => {
        setServer(new Server(workspaceID, userID, token));
    }, [userID, token, workspaceID]);

    useEffect(() => {
        let queryString = window.location.search;
        let code = null;
        let code_challenge = null;
        let apiWorkspaceID = null;
        if (queryString.indexOf('?', 1) >= 0) {
            // Remove if there was an extra ? inserted
            queryString = queryString.substring(0, queryString.indexOf('?', 1)) + '&' + queryString.substring(queryString.indexOf('?', 1) + 1);
        }
        let queryParams = new URLSearchParams(queryString);
        let connector = queryParams.get('connector');
        setConnector(connector);
        code = queryParams.get('code');
        apiWorkspaceID = queryParams.get('state');
        code_challenge = queryParams.get('code_challenge');

        if (code && token && userID) {
            const decodedCode = decodeURIComponent(code);
            const decodedCodeChallenge = code_challenge ? decodeURIComponent(code_challenge) : null;
            const decodeWorkspaceID = decodeURIComponent(apiWorkspaceID);
            const decodeConnector = decodeURIComponent(connector);
            saveAuth(decodedCode, decodeWorkspaceID, connector, decodedCodeChallenge);
        } else if (queryParams.toString() !== '') {
            console.log("Got back from API with other parameters:", queryParams.toString());
            setIsFinishSetupFlow(false);
        }
    }, [server]);

    const saveAuth = (code, workspaceID, connector, decodedCodeChallenge) => {
        let action = {
            "action": "handleOAuthRedirect", "workspaceID": workspaceID, "code": code, "connectionType": connector
        }
        if (decodedCodeChallenge) {
            action.codeChallenge = decodedCodeChallenge;
        }
        server.postData(action, (results) => {
            console.log("handleOAuthRedirect:" + results);
            if (results && results.oauth && results.oauth === 'success') {
                setIsFinishSetupFlow(true);
            } else {
                setIsFinishSetupFlow(false);
            }
        })
    }

    return (<>
        {isFinishSetupFlow && <Box>
            <Typography variant="h3" color="textSecondary" gutterBottom>Connection
                to {connector} successful</Typography>
            You can now close this page and return to Scoop.
        </Box>}
        {!isFinishSetupFlow && <Box>
            <Typography variant="h3" color="textSecondary" gutterBottom>Connection
                to {connector} unsuccessful</Typography>
            Please verify that you have the appropriate permissions to connect to the application from Scoop.
        </Box>}
    </>);
};
