// FileUpload.js
import React, {useEffect, useCallback, useState, useMemo} from 'react';
import {useApi} from '../../api/api';
import {useDropzone} from 'react-dropzone';
import {Provider, useDispatch, useSelector} from 'react-redux';
import UploadGrey from '../../assets/icons/UploadGrey.svg'
import File from '../../assets/icons/File.svg'
import Trash from '../../assets/icons/Trash.svg'


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Divider,
    Box,
    TextField,
    Select,
    MenuItem,
    LinearProgress,
} from '@mui/material';
import {set} from 'lodash';
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";
import IconButton from "@mui/material/IconButton";
import Input from "../common/Input/Input";
import Selector from "../common/Selector/Selector";


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '300px',
    minWidth: '450px',
    fontSize: '14px'
};

const focusedStyle = {
    borderColor: '#FFA6C3',
    backgroundColor: '#FCE7EE'
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#e5fbd7'

};

const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#ffd7d7'

};

// when user uploads an image to S3, make sure the filename is safe
function safeS3FileName(str) {
    return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
}


export default function FileUpload({isOpen, onClose}) {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            // 'application/vnd.ms-excel': [],
            'text/csv': [],
            'text/xlsx': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/zip': [],
            'text/html': [],
        },
    });

    // Hook to fetch data
    const {postData: postDataDefault} = useApi();
    const {postData: postDataWorkspaceactions} = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const workspaceName = useSelector((state) => state.auth.workspaceName);
    const userID = useSelector((state) => state.auth.userID);
    const userName = useSelector((state) => state.auth.userName);

    // State to store inboxes
    const [inboxes, setInboxes] = useState([]);
    const [file, setFile] = useState(null);
    const [selectedInbox, setSelectedInbox] = useState('');
    const [newInboxName, setNewInboxName] = useState('');
    const [newInboxRange, setNewInboxRange] = useState('');
    const [newInboxDescription, setNewInboxDescription] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await postDataDefault({
                    "action": "getInboxInformation",
                    "workspaceID": workspaceName,
                    "userID": userID,
                    "userName": userName,
                    "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
                });

                // Set the fetched inbox data
                if (data && data.inboxes && Array.isArray(data.inboxes)) {
                    setInboxes(data.inboxes);
                }
            } catch (error) {
                console.error("Error fetching inbox data:", error);
            }
        };

        fetchData();
    }, []);


    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const [step, setStep] = useState(0);  // 0 for file upload, 1 for additional details
    const [selectedOption, setSelectedOption] = useState('inputBoxes'); // 'dropdown' or 'inputBoxes'

    const handleNext = () => {
        setStep(1);
    };

    const handleBack = () => {
        setStep(0);
    };

    const handleUpload = async () => {
        setProgress(1);  // Initiate the progress
        // Create the payload based on selected option
        let payload = {
            "action": "uploadFile",
            "workspaceID": workspaceID,
            "userID": userID,
            "userName": userName,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev',
            "workbookRange": newInboxRange
        };

        if (selectedOption === 'dropdown') {
            payload.inboxName = selectedInbox;
            payload.isNewInbox = false;
        } else {
            payload.inboxName = newInboxName;
            payload.inboxDescription = newInboxDescription;
            if (newInboxRange.trim().length > 0)
            {
                payload.inboxRange = newInboxRange;
            }
            payload.isNewInbox = true;
        }

        // Handle the file upload here
        // First get the presigned URL
        try {
            const presignedData = await postDataWorkspaceactions({
                ...payload,
                fileName: safeS3FileName(acceptedFiles[0].name),
                fileType: acceptedFiles[0].type
            });

            // Use the presigned URL to upload the file
            await fetch(presignedData.signedRequest, {
                method: 'PUT',
                body: acceptedFiles[0],
                headers: {
                    'Content-Type': acceptedFiles[0].type
                }
            });

            setProgress(100);
            showSnackbar("File uploaded successfully! Please wait a few minutes before its data is available.", "success");
            setTimeout(() => {
                onClose();
            }, 3000);
        } catch (error) {
            console.error("Error uploading the file:", error);
            setProgress(0);  // Reset the progress
            showSnackbar("Error uploading file", "error");
            setTimeout(() => {
                onClose();
            }, 3000);
        }
    };

    const isUploadDisabled = useMemo(() => {
        if (selectedOption === 'dropdown') {
            return !selectedInbox;
        } else {
            return newInboxName.length < 3;
        }
    }, [selectedOption, selectedInbox, newInboxName]);

    const [progress, setProgress] = useState(-1);

    useEffect(() => {
        let timer;
        if (progress > 0 && progress < 100) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + 3, 100));
            }, 1000);
        } else if (progress >= 100) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const dialogActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">

            {step === 0 && (
                <>
                    <Button
                        onClick={onClose}
                        text={'Cancel'}
                        className={'button-grey'}
                    />
                    <Button
                        className={'button-purple'}
                        onClick={handleNext}
                        color="primary"
                        disabled={acceptedFiles.length === 0}
                        text={'Next'}
                    />
                </>

            )}
            {step === 1 && (
                <>
                    <Button onClick={handleBack} className={'button-grey small'} text={'Back'}>
                    </Button>
                    <Button onClick={handleUpload} className={'button-purple small'} disabled={isUploadDisabled}
                            text={'Upload'}/>
                </>
            )}
        </Box>
    )

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                title={'Dataset From Report'}
                actions={dialogActions}
                style={{minWidth: '500px'}}
            >
                {step === 0 && (
                    <section className="container">
                        <div {...getRootProps({className: 'dropzone', style})}>
                            <input {...getInputProps()} />
                            <img src={UploadGrey} alt={'upload'}/>
                            <div style={{margin: '8px 0 16px 0'}}>CSV, XLSX, HTML or other</div>
                            <div style={{color: '#2B1630'}}>Drag 'n' drop your data file here or</div>
                            <a style={{color: '#BF0A46', cursor: "pointer"}}>click here to select</a>
                        </div>
                        {acceptedFiles.length > 0 && (
                            <>
                                <ul>{acceptedFileItems}</ul>
                            </>
                        )}
                    </section>
                )}

                {step === 1 && (
                    <section>
                        <RadioGroup
                            value={selectedOption}
                            onChange={(event) => setSelectedOption(event.target.value)}
                        >
                            <Box>
                                {
                                    acceptedFiles.map(file => (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '12px 16px',
                                            backgroundColor: '#F9F9F9',
                                            borderRadius: '5px',
                                            marginBottom: '16px'
                                        }} key={file.path}>
                                            <img src={File} alt={'file'}/>
                                            <div style={{
                                                marginLeft: '8px',
                                                width: '100%',
                                                alignSelf: 'left',
                                                display: 'flex',
                                                fontSize: '14px'
                                            }}>{file.path}</div>
                                            <img src={Trash} alt={'delete'}
                                                 style={{cursor: 'pointer', height: '18px'}}/>
                                        </Box>
                                    ))
                                }
                            </Box>
                            <Box mb={2}>
                                <FormControlLabel
                                    value="inputBoxes"
                                    control={<Radio sx={{"&.Mui-checked": {color: "#E50B54"}}}/>}
                                    label={
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#2B1630'
                                        }} display="block">
                                            Upload to a new inbox
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    value="dropdown"
                                    control={<Radio sx={{"&.Mui-checked": {color: "#E50B54"}}}/>}
                                    label={
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#2B1630'

                                        }} display="block">
                                            Upload to an existing inbox
                                        </Typography>
                                    }
                                />
                            </Box>
                            {selectedOption === 'dropdown' && (
                                <Box>
                                    <Selector labelClassName={'selector-label-bold selector-label-large'}
                                              label={'Select inbox'}
                                              fullWidth value={selectedInbox}
                                              onChange={(e) => setSelectedInbox(e.target.value)}>
                                        {inboxes.map((inbox, index) => (
                                            <MenuItem key={index} value={inbox.inboxName}>{inbox.label}</MenuItem>
                                        ))}
                                    </Selector>

                                </Box>
                            )}
                            {selectedOption === 'inputBoxes' && (
                                <Box>
                                    <FormControl component="fieldset" fullWidth sx={{display: 'flex', gap: '16px',}}>
                                        <Input
                                            padding={'8px'}
                                            fullWidth
                                            placeholder={'My data file'}
                                            label="Name"
                                            margin="normal"
                                            value={newInboxName}
                                            onChange={(e) => setNewInboxName(e.target.value)}
                                        />
                                        {acceptedFiles[0].path.endsWith('.xlsx') && <Input
                                            padding={'8px'}
                                            fullWidth
                                            placeholder={'My named range'}
                                            label="Named Range(s) to Use (optional, comma separated)"
                                            margin="normal"
                                            value={newInboxRange}
                                            onChange={(e) => setNewInboxRange(e.target.value)}
                                        />
                                        }
                                        <Input
                                            multiline
                                            rows={2}
                                            fullWidth
                                            label="Description (optional)"
                                            margin="normal"
                                            value={newInboxDescription}
                                            onChange={(e) => setNewInboxDescription(e.target.value)}
                                        />
                                    </FormControl>
                                </Box>
                            )}
                        </RadioGroup>

                        {progress !== -1 &&
                            <LinearProgress variant="determinate" value={progress} style={{marginTop: '20px'}}/>
                        }
                    </section>
                )}
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
