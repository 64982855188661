import {
    Box,
    DialogContentText, Typography,
} from "@mui/material";
import * as React from "react";
import dayjs from 'dayjs';
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";
import {ScoopDatePicker} from "../common/DatePicker/ScoopDatePicker";
import Selector from "../common/Selector/Selector";
import MenuItem from "@mui/material/MenuItem";
import {useEffect} from "react";
import {Switch} from "../common/Switch/Switch";
import {InfoTooltip} from "../common/InfoTooltop/InfoTooltip";

const options = [
    {value: 'GreaterThan', label: 'Greater Than'},
    {value: 'LessThan', label: 'Less Than'},
    {value: 'Equals', label: 'Equals'},
    {value: 'Between', label: 'Between'},
    {value: 'GreaterThanOrEquals', label: 'Greater Than or Equals'},
    {value: 'LessThanOrEquals', label: 'Less Than or Equals'}
];

const periodOptions = [
    {value: 'LastYear', label: 'Last Year', index: 0},
    {value: 'LastQuarter', label: 'Last Quarter', index: 1},
    {value: 'LastMonth', label: 'Last Month', index: 2},
    {value: 'LastWeek', label: 'Last Week', index: 3},
    {value: 'Yesterday', label: 'Yesterday', index: 4},
    {value: 'Now', label: 'Now', index: 5},
    {value: 'Tomorrow', label: 'Tomorrow', index: 6},
    {value: 'NextWeek', label: 'Next Week', index: 7},
    {value: 'NextMonth', label: 'Next Month', index: 8},
    {value: 'NextQuarter', label: 'Next Quarter', index: 9},
    {value: 'NextYear', label: 'Next Year', index: 10}
]

export function TimeRangeDialog({
                                    open, setOpen,
                                    config, setConfig,
                                    chartProperties
                                }) {

    const [selectedDateOption, setSelectedDateOption] = React.useState(options[4].value);
    const [range, setRange] = React.useState(undefined);
    const [singleDate, setSingleDate] = React.useState(undefined);
    const [relative, setRelative] = React.useState(true);
    const [startPeriod, setStartPeriod] = React.useState(periodOptions[0].value);
    const [endPeriod, setEndPeriod] = React.useState(periodOptions[5].value);

    const parseDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const parsedDate = new Date();
        parsedDate.setFullYear(year, month - 1, day);
        parsedDate.setHours(0, 0, 0, 0);
        return parsedDate;
    };

    useEffect(() => {
        const parsedDates = [];
        let operator = null;

        if (config.from) {
            parsedDates.push(parseDate(config.from));
            operator = 'GreaterThanOrEquals';
        }

        if (config.to) {
            parsedDates.push(parseDate(config.to));
            operator = 'LessThanOrEquals';
        }

        if (parsedDates.length > 1) {
            setRange(parsedDates);
        } else {
            setSingleDate(parsedDates[0]);
        }

        if (config.from && config.to) {
            if (config.from === config.to) {
                operator = 'Equals';
            } else {
                operator = 'Between';
            }
            setSelectedDateOption(operator);
        }

    }, [config.from, config.to, open]);

    const handleDateChange = (e) => {
        setSelectedDateOption(e.target.value);
    }

    function handleClose(event) {
        setOpen(false);
    }

    function handleSave() {
        let from;
        let to;
        config.timeRange = "Custom";
        if (relative) {
            config.fromPeriod = startPeriod;
            config.toPeriod = endPeriod;
        } else
        {
            if (range === undefined && singleDate === undefined) return;
            if (selectedDateOption !== 'Between') {
                switch (selectedDateOption) {
                    case 'GreaterThan':
                        from = dayjs(singleDate).add(1, 'day').format('YYYY-MM-DD');
                        to = undefined;
                        break;
                    case 'LessThan':
                        from = undefined;
                        to = dayjs(singleDate).subtract(1, 'day').format('YYYY-MM-DD');
                        break;
                    case 'Equals':
                        from = dayjs(singleDate).format('YYYY-MM-DD');
                        to = dayjs(singleDate).format('YYYY-MM-DD');
                        break;
                    case 'GreaterThanOrEquals':
                        from = dayjs(singleDate).format('YYYY-MM-DD');
                        to = undefined;
                        break;
                    case 'LessThanOrEquals':
                        from = undefined;
                        to = dayjs(singleDate).format('YYYY-MM-DD');
                        break;
                }
            } else {
                from = dayjs(range[0]).format('YYYY-MM-DD');
                to = dayjs(range[1]).format('YYYY-MM-DD');
            }
            config.from = from;
            config.to = to;
        }
        chartProperties.getResults(config);
        setOpen(false);
        setConfig({...config});
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'}></Button>
            <Button onClick={handleSave} className={'button-purple'} text={'Apply'}></Button>
        </Box>
    )

    let startIndex = 0;
    for(;startIndex < periodOptions.length; startIndex++){
        if (startPeriod === periodOptions[startIndex].value) {
            break;
        }
    }

    return (
        <Dialog style={{width: '500px'}} open={open} onClose={handleClose} title={'Filter Time Range'}
                actions={actions}>
            <DialogContentText>
                Specify a custom time range
            </DialogContentText>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography sx={{fontSize: '12px', fontWeight: 600}}>Relative Dates</Typography>
                <InfoTooltip title={"Specify a fixed date range vs. one based on relative dates"}/>
                <Switch checked={relative}
                        onChange={() => {
                            setRelative(!relative);
                        }}
                ></Switch>
            </Box>
            {relative && <Box sx={{display: 'flex', m: 0, mt: 2, mb: 2, alignItems: 'center', gap: '16px'}}>
                <Selector
                    value={startPeriod}
                    label={'Starting'}
                    sx={{height: 36}}
                    labelClassName={'selector-label-bold'}
                    onChange={(e) => setStartPeriod(e.target.value)}>
                    {
                        periodOptions.map((option, index) => {
                            return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        })
                    }
                </Selector>
                <Selector
                    value={endPeriod}
                    label={'Ending'}
                    sx={{height: 36}}
                    labelClassName={'selector-label-bold'}
                    onChange={(e) => setEndPeriod(e.target.value)}>
                    {
                        periodOptions.filter((item)=>{return item.index >= startIndex}).map((option, index) => {
                            return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        })
                    }
                </Selector>
            </Box>}
            {!relative &&
                <Box sx={{display: 'flex', m: 0, mt: 2, mb: 2, alignItems: 'center', gap: '16px'}}>
                    <Selector
                        value={selectedDateOption}
                        label={'Select option'}
                        sx={{height: 36}}
                        labelClassName={'selector-label-bold'}
                        onChange={(e) => handleDateChange(e)}
                    >
                        {
                            options.map((option, index) => {
                                return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            })
                        }
                    </Selector>
                    <ScoopDatePicker
                        label={`Select ${selectedDateOption === 'Between' ? 'range' : 'date'}`}
                        value={selectedDateOption === 'Between' ? range : singleDate}
                        onChange={(newValue) => selectedDateOption === 'Between' ? setRange(newValue) : setSingleDate(newValue)}
                        range={selectedDateOption === 'Between'}
                    />
                </Box>}
        </Dialog>
    )
}
