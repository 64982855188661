import {OBJECT_TYPES} from "../types";

export const Insight_MIN_SIZE = {"width": 200, "height": 200};

export class InsightClass {
  constructor(id, title, x, y, width, height, chartProps, wrapperStyles) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.wrapperStyles = wrapperStyles || {};
    this.isBlank = true;
    this.type = OBJECT_TYPES.INSIGHT;
    this.content = {'insightKey': chartProps?.insightKey || null , 'workspaceID': chartProps?.workspaceID || null};
  }

  // initialize w sample data from a working chart
  static newInsight(title, chartProps, zoom, wrapperStyles, x, y) {
    const canvasScrollElement = document.getElementById('canvas-scrollable')
    return new InsightClass(
        Math.random(),
        title || 'My Summary',
        x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
        y ? y : (200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
        700,
        500,
        chartProps,
        wrapperStyles,
    );
  }
}
