import Dialog from "../../../common/Dialog/Dialog";
import {Box, Typography} from "@mui/material";
import './NewDatasetModal.css';
import SalesforceIcon from '../../../../assets/icons/Salesforce.svg'
import LinkedInIcon from '../../../../assets/icons/LinkedIn.svg';
import GoogleSheets from '../../../../assets/icons/GoogleSheets.svg';
import GoogleAnalytics from '../../../../assets/icons/GoogleAnalytics.svg';
import JiraIcon from '../../../../assets/icons/Jira.svg';
import HubspotIcon from '../../../../assets/icons/Hubspot.svg';
import AirtableIcon from '../../../../assets/icons/Airtable.svg';
import OtherIcon from '../../../../assets/icons/Email.svg'
// import AWS from '../../../../assets/icons/AWS.svg';
import GoogleDataStudioIcon from '../../../../assets/icons/GoogleDataStudio.svg';
import SharetribeIcon from '../../../../assets/icons/Sharetribe.svg';
import DatabaseBlack from '../../../../assets/icons/DatabaseBlack.svg'
import PipedriveIcon from '../../../../assets/icons/PipedriveIcon.png';
import GoogleAnalyticsIcon from '../../../../assets/icons/google-analytics.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import ApolloIcon from '../../../../assets/icons/apollo.svg';
import MondayIcon from '../../../../assets/icons/Monday.png';
import {useEffect, useRef, useState} from "react";
import {Hubspot} from "../../Appconnect/Hubspot/Hubspot";
import {LinkedIn} from "../../Appconnect/LinkedIn/LinkedIn";
import {GoogleDataStudio} from "../../Appconnect/GoogleDataStudio/GoogleDataStudio";
import {Sharetribe} from "../../Appconnect/Sharetribe/Sharetribe";
import {Apollo} from "../../Appconnect/Apollo/Apollo";
import {Jira} from "../../Appconnect/Jira/Jira";
import {SelectType} from "./SelectType";
import {Salesforce} from "../../Appconnect/GMail/Salesforce";
import {Other} from "../../Appconnect/GMail/Other";
import {Database} from "../../Appconnect/Database/Database";
import datasourceDefs from "../../../../api/datasourceConfig";
import {SelectAPIorReport} from "./SelectAPIorReport";
import {APIConnector} from "../../Appconnect/API/APIConnector";

const renderComingSoon = () => {
    return (<Typography
        sx={{
            paddingBottom: '1px',
            paddingRight: '4px',
            fontSize: '10px',
            position: 'absolute',
            top: '2px',
            right: '1px',
            color: '#E50B54',
            fontFamily: 'Inter',
            fontStyle: 'normal',
        }}
    >
        {/* Coming Soon! */}
    </Typography>)
}

const sources = [
    { name: 'Salesforce', icon: SalesforceIcon, backgroundColor: '#00A1E00D', step: 3, sourceValue: 'Salesforce' },
    { name: 'Google Sheets', icon: GoogleSheets, backgroundColor: '#21A4640D', step: 1, sourceValue: 'GoogleSheets' },
    { name: 'HubSpot', icon: HubspotIcon, backgroundColor: '#FF7A590D', step: 3, sourceValue: 'HubSpot' },
    { name: 'Pipedrive', icon: PipedriveIcon, backgroundColor: '#21A4640D', step: 3, sourceValue: 'Pipedrive' },
    { name: 'Close', icon: CloseIcon, backgroundColor: '#FF7A590D', step: 2, sourceValue: 'Close' },
    { name: 'Monday', icon: MondayIcon, backgroundColor: '#FF7A590D', step: 2, sourceValue: 'Monday' },
    { name: 'LinkedIn', icon: LinkedInIcon, backgroundColor: '#FF7A590D', step: 1, sourceValue: 'LinkedIn' },
    { name: 'Airtable', icon: AirtableIcon, backgroundColor: '#0000000D', step: 2, sourceValue: 'Airtable' },
    { name: 'Database', icon: DatabaseBlack, backgroundColor: '#0000000D', step: 1, sourceValue: 'Database' },
    { name: 'Google Analytics', icon: GoogleAnalyticsIcon, backgroundColor: 'rgba(38, 132, 255, 0.05)', step: 2, sourceValue: 'GoogleAnalytics' },
    { name: 'Google Data Studio', icon: GoogleDataStudioIcon, backgroundColor: 'rgba(38, 132, 255, 0.05)', step: 1, sourceValue: 'GoogleDataStudio' },
    { name: 'Apollo', icon: ApolloIcon, backgroundColor: 'rgba(38, 132, 255, 0.05)', step: 3, sourceValue: 'Apollo' },
    { name: 'Sharetribe', icon: SharetribeIcon, backgroundColor: 'rgba(38, 132, 255, 0.05)', step: 1, sourceValue: 'Sharetribe' },
    { name: 'Jira', icon: JiraIcon, backgroundColor: '#0000000D', step: 1, sourceValue: 'Jira' },
    { name: 'Other', icon: OtherIcon, backgroundColor: '#00A1E00D', step: 1, sourceValue: 'Other' },
];


export const ConnectDatasource = ({onClose, setAlert}) => {
    const [source, setSource] = useState('')
    const [type, setType] = useState('')
    const [step, setStep] = useState(0)
    const [isSnapshotDefault, setIsSnapshotDefault] = useState(false)
    const [basicConfiguration, setBasicConfiguration] = useState(null)
    const [isAPI, setIsAPI] = useState(false);
    const [visibleSources, setVisibleSources] = useState([]);
    const containerRef = useRef(null);
    const sentinelRef = useRef(null);

    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setVisibleSources((prevState) => {
                return [...prevState, ...sources]
            })
        }
    }

    useEffect(() => {
        if (step === 0 && visibleSources.length === 0) {
            setVisibleSources(sources.slice(0, 15));
        } else {
            setVisibleSources([]);
        }
    }, [step])

    useEffect(() => {
        if (sentinelRef.current && containerRef.current) {
            const options = {
                root: containerRef.current,
                threshold: 0.1
            };

            const observer = new IntersectionObserver(handleObserver, options);
            observer.observe(sentinelRef.current);

            return () => {
                if (sentinelRef.current) {
                    observer.unobserve(sentinelRef.current)
                }
            };
        }
    }, [visibleSources, step]);

    const handleContinue = (data) => {
        setBasicConfiguration(data)
        setStep(2)
    }

    useEffect(() => {
        datasourceDefs.find(f => f.name === source)?.isSnapshotDefault ? setIsSnapshotDefault(true) : setIsSnapshotDefault(false)
        // console.log(datasourceDefs.find(f => f.name === source), source)
    }, [source])

    const renderSource = () => {
        switch (source) {
            case 'HubSpot':
                if (isAPI) {
                    return <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                        connectorType="HubSpot"
                    />
                } else {
                    return <Hubspot
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                    />
                }
            case
            'LinkedIn'
            :
                return <LinkedIn
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                />
            case
            'GoogleDataStudio'
            :
                return <GoogleDataStudio
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                />
            case
            'Salesforce'
            :
                if (isAPI) {
                    return <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                        connectorType="Salesforce"
                    />
                } else {
                    return <Salesforce
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                }
            case
            'Database'
            :
                return <Database
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />
            case
            'Sharetribe'
            :
                return <Sharetribe
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />
            case 'Pipedrive':
            case 'Airtable':
            case 'GoogleAnalytics':
            case 'Jira':
            case 'Close':
            case 'Monday':
            case 'GoogleSheets':
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(0)} type={type}
                    setAlert={setAlert}
                    connectorType={source}
                />
            case
            'Apollo'
            :
                if (isAPI) {
                    return <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                        connectorType="Apollo"
                    />
                } else {
                    return <Apollo
                        selectedInbox={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)} type={type}
                        setAlert={setAlert}
                        connectorType="Apollo"
                    />
                }
            case
            'Other'
            :
                return <Other
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />

            default:
                return renderSourceSelection()
        }
    }

    const renderSourceSelection = () => {
        return (
            <Dialog
                open={true}
                onClose={onClose}
                title={'Dataset from Source'}
            >
                <Typography sx={{fontSize: '14px', color: '#635566'}}>
                    Select the datasource you want to Scoop data from
                </Typography>
                <Box ref={containerRef}
                     className={'sources-grid-container'}
                >
                    {visibleSources && visibleSources.map((source, index) => (
                        <Box
                            key={index}
                            className={'source-box'}
                            onClick={() => {
                                setStep(source.step);
                                setSource(source.sourceValue);
                            }}
                        >
                            <img
                                src={source.icon}
                                alt={source.name}
                                className={'source-icon'}
                                style={{backgroundColor: source.backgroundColor}}
                            />
                            <Typography sx={{fontSize: '14px'}}>{source.name}</Typography>
                        </Box>
                    ))}
                    <div ref={sentinelRef} style={{ height: '1px' }} />
                </Box>
            </Dialog>
        );
    };

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return renderSourceSelection()
            case 1:
                return <SelectType
                    handleContinue={handleContinue}
                    index={1}
                    setType={setType}
                    step={step}
                    setStep={setStep}
                    onClose={onClose}
                    isSnapshotDefault={isSnapshotDefault}
                    continueButton={"Continue"}
                />
            case 2:
                return renderSource()
            case 3:
                return <SelectAPIorReport
                    index={3}
                    setType={setType}
                    setStep={setStep}
                    onClose={onClose}
                    handleContinue={(active) => {
                        if (active === 'Report Data Source') {
                            setIsAPI(false);
                            setStep(1);
                        } else if (active === 'API Data Source') {
                            setIsAPI(true);
                            setStep(2);
                        }
                    }}
                    sourceName={source}
                />
            default:
                return renderSourceSelection()
        }
    }

    return (<>
        {renderStepContent()}
    </>)
}