import React, {useEffect, useMemo, useState} from "react";
import '../StyleTab.css';
import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import {ThemeItem} from "../ThemeItem/ThemeItem";
import MagnifyingGlass from "../../../../../../assets/icons/MagnifyingGlass.svg";
import Plus from "../../../../../../assets/icons/Plus.svg";
import DeleteDialog from "../../../../../common/Dialog/DeleteDialog";
import _ from "lodash";
import {DEFAULT_CHART_PREFERENCES} from "../styleConsts";
import {ScoopTheme} from "../../../../../Insights/Style";

export const ThemesConfig = ({
                                 server,
                                 config,
                                 setConfig,
                                 setSelectedProp,
                                 workspaceMetadata,
                                 setCreateThemeOpen,
                                 previousTheme,
                                 previousStyleOverrides,
                                 getWorkspaceMetadata
}) => {

    const [search, setSearch] = useState('');
    const [themeId, setThemeId] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const themes = useMemo(() => workspaceMetadata?.themes || [], [workspaceMetadata]);

    useEffect(() => {
        if (!config.themeID) {
            handleChangeTheme({
                themeID: 'defaultTheme',
                themeName: 'Default theme',
                colorScheme: {
                    backgroundColor: '#FFFFFF',
                    colors: ScoopTheme.color,
                    darkTheme: false
                }
            })
        }
    }, []);

    const handleChangeTheme = (theme) => {
        const newConfig = _.cloneDeep(config)
        newConfig.themeID = theme.themeID
        if (theme.themeID === previousTheme) {
            newConfig.styleOverrides = previousStyleOverrides
        } else {
            newConfig.styleOverrides = DEFAULT_CHART_PREFERENCES;
        }
        setConfig({...newConfig})
    }

    const handleDeleteTheme = (themeID) => {
        setThemeId(themeID)
        setDeleteOpen(true)
    }

    const confirmDeleteTheme = () => {
        server.postData({
            "action": "deleteObject",
            "class": "scoop.presentation.Theme",
            "key": themeId
        }, () => {
            getWorkspaceMetadata()
            setThemeId('')
            setDeleteOpen(false)
        })
    }

    const handleCancel = () => {
        setThemeId('')
        setDeleteOpen(false)
    }

    const compareThemes = (a, b) => {
        if (a.themeName < b.themeName) return -1
        if (a.themeName > b.themeName) return 1
        return 0
    }

    return (
        <>
            <Box className={'themes-searchbar'}>
                <TextField
                    value={search}
                    placeholder={'Search themes'}
                    onChange={(e) => setSearch(e.target.value)}
                    variant={'outlined'}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '38px !important',
                            width: '100% !important',
                            marginTop: '-10px !important',
                            borderRadius: '5px !important',
                            fontFamily: 'Inter, sans-serif'
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <img src={MagnifyingGlass} alt={'search'}/>
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton
                    onClick={() => setCreateThemeOpen(true)}
                    sx={{
                        height: 38,
                        width: 38,
                        backgroundColor: '#F2F2F2',
                        marginLeft: '5px',
                        borderRadius: '5px'
                    }}
                >
                    <img src={Plus} alt={'add-theme'} />
                </IconButton>
            </Box>
            <Box className={'themes-list'}>
                <ThemeItem
                    key={'defaultTheme'}
                    theme={{
                        themeID: 'defaultTheme',
                        themeName: 'Default theme',
                        colorScheme: {
                            backgroundColor: '#FFFFFF',
                            colors: ScoopTheme.color.map(c => ({val: c}))
                        },
                    }}
                    selectedTheme={config.themeID}
                    onClick={() => handleChangeTheme({
                        themeID: 'defaultTheme',
                        themeName: 'Default theme',
                        colorScheme: {
                            backgroundColor: '#FFFFFF',
                            colors: ScoopTheme.color,
                            darkTheme: false
                        }
                    })}
                    setSelectedProp={setSelectedProp}
                />
                {
                    themes.filter(t => t.themeName && t.themeName.toLowerCase().includes(search.toLowerCase()))
                        .sort(compareThemes).map(theme => (
                            <ThemeItem
                                key={theme.themeID}
                                theme={theme}
                                selectedTheme={config.themeID}
                                onClick={() => handleChangeTheme(theme)}
                                setSelectedProp={setSelectedProp}
                                handleDelete={handleDeleteTheme}
                            />
                        ))
                }
            </Box>
            <DeleteDialog
                handleCancel={handleCancel}
                title={'theme'}
                open={deleteOpen}
                handleDelete={confirmDeleteTheme}
                description={'Are you sure you want to delete this theme?'}
            />
        </>
    )
}
